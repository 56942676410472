<template>
    <v-dialog v-model="isOpen" max-width="1600px" persistent>
        <v-card>
            <v-card-title class="px-4 py-4 align-center" style="gap: 8px;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important; transform: translateY(-1px)" 
                    class="pt-0"
                    @click.stop="cerrarModal"
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <h6 v-if="institucion && anio" class="text-body-1 text-uppercase" style="word-break: break-word;">
                    <span class="font-weight-bold">{{ ` ${institucion.nombre} ` }}</span>
                    <span class="font-weight-bold">año {{ anio.anio }}</span>
                </h6>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-4 py-4" style="overflow: auto; max-width: 100%;">
                <div style="min-height: 450px;  min-width: 1000px; position: relative;" class="px-2 py-2">
                    <DarkOverlayLoader v-if="resumenMensual.isLoading" />
                    <apexchart
                        v-else
                        style="min-width: 1000px;"
                        height="100%" 
                        type="bar" 
                        :options="options" 
                        :series="series"
                    />
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { toMoneyFormat } from '@/utils/data';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DarkOverlayLoader from '../utils/DarkOverlayLoader.vue';

export default {
    name: 'ModalPlanificacionMensual',
    components: { DarkOverlayLoader },
    props: {
        isOpen: { type: Boolean },
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        resumenMensual: createLoadable([]),
        institucion: null,
        anio: null,
    }),
    computed: {
        series() {
            const data = this.resumenMensual.data.map((mes) => mes.monto ?? 0);

            return [{ name: 'Montos', data }];
        },
        options() {
            const categories = this.resumenMensual.data.map((estadistica) => `${estadistica.nombre_mes} - ${estadistica.anio}`)

            return ({
                chart: {
                    height: 400,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return toMoneyFormat(val, false, { notation: 'compact', maximumFractionDigits: 2 });
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories,
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 1000],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        formatter: function (val) {
                            return toMoneyFormat(val);
                        }
                    },
                },
                title: {
                    floating: true,
                    offsetY: 0,
                    align: 'center',
                    style: {
                        color: '#444'
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                }
            })
        },
    },
    methods: {
        // UI
        cerrarModal() {
            this.$emit('update:is-open', false);
            this.resumenMensual = createLoadable([]);
            this.institucion = null;
            this.anio = null;
        },
        manejarEventos(evento, payload) {
            if (evento !== this.eventoBusqueda) return;

            this.institucion = payload.institucion;
            this.anio = payload.anio_fiscal;

            this.cargarResumenMensual({ 
                id_anio_fiscal: payload.anio_fiscal?.id,
                id_institucion: payload.institucion?.id,
                id_forma_contratacion: payload.modalidad,
            });
        },
        // Data
        async cargarResumenMensual(filtros) {
            toggleLoadable(this.resumenMensual);
            const { data } = await this.services.ReportePlanificacionPac.cargarResumenMensual(filtros);
            setLoadableResponse(this.resumenMensual, data);
        },
    },
    created() {
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>