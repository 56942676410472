<template>
    <v-dialog v-model="isOpen" max-width="1600px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 d-block" style="position: sticky; top: 0; background: #FFF; z-index: 20;">
                <div class="d-flex px-4 py-4 align-center" style="gap: 8px;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important; transform: translateY(-1px)" 
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <v-divider />
            </v-card-title>
            <v-card-text class="px-4 py-4">
                <v-row>
                    <v-col cols="12" lg="5" style="position: relative;">
                        <DarkOverlayLoader v-if="resumenPorModalidad.isLoading" />
                        <template v-else>
                            <EmptyComponent 
                                :empty="resumenPorModalidad.data.length === 0" 
                                text="No hay datos disponibles" 
                                style="height: 100%;"
                                colored
                            >
                                <template v-slot:empty-content>
                                    <div style="height: 100%" class="d-flex flex-column align-center justify-center">
                                        <v-icon class="empty-indicator-icon" color="primary">mdi-chart-pie</v-icon>
                                        <p class="empty-indicator-text mt-3">No hay datos disponibles</p>
                                    </div>
                                </template>
                                <apexchart :options="options" :series="series" class="mx-auto" />
                            </EmptyComponent>
                        </template>
                    </v-col>
                    <v-col cols="12" lg="7">
                        <h6 class="text-subtitle-1 font-weight-bold text-uppercase">Lista de procesos</h6>
                        <v-divider class="mt-2" />
                        <DataTableComponent 
                            :headers="headers"
                            :items="procesos.data"
                            :show_loading="procesos.isLoading"
                            :total_registros="registrosTotales"
                            @paginar="manejarPaginacion"
                            v-models:pagina="paginacion.pagina"
                            v-models:select="paginacion.registrosPorPagina"
                            class="mt-4"
                            dense
                            no-gutters
                        >
                            <template v-slot:item.nombre_proceso="{ item }">
                                <p class="mb-0">
                                    <b>{{ item.codigo_proceso }}:</b>
                                    <span class="ml-1">{{ item.nombre_proceso }}</span>
                                </p>
                            </template>
                            <template v-slot:item.monto_planificado="{ item }">
                                {{ toMoneyFormat(item.monto_planificado, false, { maximumFractionDigits: 8 }) }}
                            </template>
                            <template v-slot:item.fecha_inicio="{ item }">
                                {{ formatDate(item.fecha_inicio, "DD/MM/YYYY") }}
                            </template>
                        </DataTableComponent>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { createLoadable, createPageable, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';
import DarkOverlayLoader from '../utils/DarkOverlayLoader.vue';
import DataTableComponent from '../DataTableComponent.vue';
import { toMoneyFormat } from '@/utils/data';
import EmptyComponent from '../utils/EmptyComponent.vue';
import { formatDate } from '@/utils/datetime';

export default {
    name: 'ModalPlanificacionPorModalidad',
    components: { DarkOverlayLoader, DataTableComponent, EmptyComponent },
    props: {
        isOpen: { type: Boolean },
        eventoCarga: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        resumenPorModalidad: createLoadable([]),
        procesos: createPageable([], 10),
        filtrosActivos: {},
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
    }),
    computed: {
        headers() {
            return [
                { text: 'Proceso', value: 'nombre_proceso', sortable: false, align: 'center' },
                { text: 'Modalidad', value: 'nombre_modalidad', sortable: false, align: 'center' },
                { text: 'Fecha de ejecución', value: 'fecha_inicio', sortable: false, align: 'center' },
                { text: 'Monto', value: 'monto_planificado', sortable: false, align: 'center' },
            ]
        },
        registrosTotales() {
            return this.procesos.pagination.total_rows;
        },
        series() {
            return this.resumenPorModalidad.data.map((modalidad) => modalidad.monto ?? 0);
        },
        options() {
            const labels = this.resumenPorModalidad.data.map((modalidad) => modalidad.nombre);

            return {
                chart: {
                    type: "donut",
                },
                labels,
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            size: '65%'
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                legend: {
                    position: 'top'
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return toMoneyFormat(value);
                        },
                    },
                },
            };
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        formatDate,
        manejarPaginacion(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina };

            this.cargarProcesos(this.filtrosActivos);
        },
        cerrarModal() {
            this.$emit('update:is-open', false);
            this.filtrosActivos = {};
            this.procesos = createPageable([], 10);
            this.resumenPorModalidad = createLoadable([]);
            this.paginacion = {
                pagina: 1,
                registrosPorPagina: 10,
            };
        },
        // DATA
        manejarEventos(evento, payload) {
            if (evento !== this.eventoCarga) return;

            const filtros = {
                id_anio_fiscal: payload.anio_fiscal?.id, 
                id_institucion: payload.institucion?.id,
                id_forma_contratacion: payload.modalidad,
            }
            this.filtrosActivos = { ...filtros };

            this.cargarResumenPorModalidad({ ...filtros });
            this.cargarProcesos({ ...filtros });
        },
        // Data
        async cargarResumenPorModalidad(filtros) {
            toggleLoadable(this.resumenPorModalidad);
            const { data } = await this.services.ReportePlanificacionPac.cargarResumenPorModalidad(filtros);
            setLoadableResponse(this.resumenPorModalidad, data);
        },
        async cargarProcesos(filtros) {
            const filtrosConPaginacion = {
                ...filtros,
                pagination: true,
                page: this.paginacion.pagina,
                per_page: 
                this.paginacion.registrosPorPagina,
            }

            togglePageable(this.procesos);
            const { data, headers } = await this.services.ReportePlanificacionPac.cargarProcesosConPlanificacion(filtrosConPaginacion);
            setPageableResponse(this.procesos, data, headers);
        },
    },
    created() {
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>